import request from '@/request';
export default class RestoreApi {
    static createProject() {
        return request.post('restore/create-project').then(({ data }) => data);
    }
    static describeBackup(params) {
        return request
            .get('restore/describe-backup', {
            params,
        })
            .then(({ data }) => data);
    }
    static describeObjects(body) {
        return request.post('restore/describe-objects', body).then(({ data }) => data);
    }
    static objectRecords(body) {
        return request.post('restore/object-records', body).then(({ data }) => data);
    }
    static restore(params, body) {
        return request
            .post('restore', body, {
            params,
        })
            .then(() => undefined);
    }
}
