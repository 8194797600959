import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
import { DeclarativeFilterItem } from '@/classes/declarative-filter-item';
const DECLARATIVE_FILTER_UPDATED = 'DECLARATIVE_FILTER_UPDATED';
let DeclarativeFilter = class DeclarativeFilter extends Vue {
    constructor() {
        super(...arguments);
        this.tableFields = [
            { key: 'index', label: '#', thStyle: { width: '30px' } },
            { key: 'name', label: 'Field', thStyle: { width: '20%' } },
            { key: 'option', label: 'Option', thStyle: { width: '20%' } },
            { key: 'value', label: 'Value' },
            { key: 'condition', label: '', thStyle: { width: '80px' } },
            { key: 'remove', label: '', thStyle: { width: '40px' } },
        ];
        this.showCustomFilter = false;
        this.customFilter = '';
    }
    get fieldNames() {
        return Object.keys(this.fields);
    }
    onModelUpdated() {
        this.$emit(DECLARATIVE_FILTER_UPDATED, {
            ...this.model,
            customLogic: this.showCustomFilter ? this.customFilter : undefined,
        });
    }
    mounted() {
        this.showCustomFilter = !!this.model.customLogic;
        this.customFilter = this.model.customLogic || '';
    }
    add() {
        if (this.model.filters.length !== this.maxItems) {
            this.$emit(DECLARATIVE_FILTER_UPDATED, {
                ...this.model,
                filters: [...this.model.filters, new DeclarativeFilterItem('', null, '', this.fields)],
            });
        }
    }
    remove(item) {
        this.$emit(DECLARATIVE_FILTER_UPDATED, {
            ...this.model,
            filters: this.model.filters.filter(existedItem => existedItem !== item),
        });
    }
};
__decorate([
    Model(DECLARATIVE_FILTER_UPDATED, {
        default: () => ({
            filters: [],
        }),
    })
], DeclarativeFilter.prototype, "model", void 0);
__decorate([
    Prop({
        required: true,
    })
], DeclarativeFilter.prototype, "fields", void 0);
__decorate([
    Prop({
        default: 5,
    })
], DeclarativeFilter.prototype, "maxItems", void 0);
__decorate([
    Watch('customFilter', { deep: true }),
    Watch('showCustomFilter')
], DeclarativeFilter.prototype, "onModelUpdated", null);
DeclarativeFilter = __decorate([
    Component({})
], DeclarativeFilter);
export default DeclarativeFilter;
