import { __decorate } from "tslib";
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { CsvRecordPaginationActionEnum, } from '@flosum-backup/contracts';
import Expandable from '@/components/shared/Expandable.vue';
import RestoreApi from '@/services/api/restore.api';
let SelectRecords = class SelectRecords extends Vue {
    constructor() {
        super(...arguments);
        this.pagination = {
            isFirstPage: true,
            isLastPage: true,
            startIndexRecord: 0,
            startIndexFile: 0,
            finishIndexRecord: 0,
            finishIndexFile: 0,
            action: CsvRecordPaginationActionEnum.Init,
        };
        this.loading = false;
        this.openedObject = '';
        this.openedRecordId = '';
        this.records = [];
    }
    get objects() {
        return this.settings.executionStepsDetails.map(step => step.name);
    }
    get objectFields() {
        return this.openedObjectStep?.fields || [];
    }
    get openedObjectStep() {
        return this.settings.executionStepsDetails.find(step => step.name === this.openedObject) || null;
    }
    nextPage() {
        this.pagination.action = CsvRecordPaginationActionEnum.Next;
        this.$nextTick(() => this.fetchRecords());
    }
    prevPage() {
        this.pagination.action = CsvRecordPaginationActionEnum.Prev;
        this.$nextTick(() => this.fetchRecords());
    }
    isSelectedId(id) {
        return !!this.openedObject && !!this.selected[this.openedObject] && this.selected[this.openedObject].includes(id);
    }
    toggleRecord(id) {
        if (this.isSelectedId(id)) {
            this.$emit('changed', {
                ...this.selected,
                [this.openedObject]: this.selected[this.openedObject].filter(selectedId => selectedId !== id),
            });
        }
        else {
            this.$emit('changed', {
                ...this.selected,
                [this.openedObject]: [...(this.selected[this.openedObject] || []), id],
            });
        }
    }
    openObject(object) {
        if (object === this.openedObject) {
            this.openedObject = '';
        }
        else {
            this.openedObject = object;
            this.fetchRecords(true);
        }
    }
    openRecord(recordId) {
        this.openedRecordId = recordId === this.openedRecordId ? '' : recordId;
    }
    async fetchRecords(initial = false) {
        try {
            this.loading = true;
            this.records = [];
            if (!this.openedObjectStep) {
                return;
            }
            if (initial) {
                this.pagination = {
                    isFirstPage: true,
                    isLastPage: true,
                    startIndexRecord: 0,
                    startIndexFile: 0,
                    finishIndexRecord: 0,
                    finishIndexFile: 0,
                    action: CsvRecordPaginationActionEnum.Init,
                };
            }
            const { backupId, projectName, pagination } = this;
            const { name, backupOptionId, declarativeFilter, declarativeFilterCustomLogic } = this.openedObjectStep;
            const { records, csvRecordPaginationOptionResponse } = await RestoreApi.objectRecords({
                backupId,
                projectName,
                backupOptionId: backupOptionId,
                declarativeFilter: declarativeFilter || [],
                declarativeFilterLogic: declarativeFilterCustomLogic,
                csvRecordPaginationOptionRequest: pagination,
                objectName: name,
                organizationId: this.organizationId,
                storageId: this.storageId,
            });
            this.records = records;
            this.pagination = {
                ...csvRecordPaginationOptionResponse,
                action: this.pagination.action,
            };
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Model('changed')
], SelectRecords.prototype, "selected", void 0);
__decorate([
    Prop()
], SelectRecords.prototype, "settings", void 0);
__decorate([
    Prop()
], SelectRecords.prototype, "backupId", void 0);
__decorate([
    Prop()
], SelectRecords.prototype, "storageId", void 0);
__decorate([
    Prop()
], SelectRecords.prototype, "projectName", void 0);
__decorate([
    Prop()
], SelectRecords.prototype, "organizationId", void 0);
SelectRecords = __decorate([
    Component({
        components: {
            Expandable,
        },
    })
], SelectRecords);
export default SelectRecords;
