var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"declarative-filter"},[(_vm.model.filters)?_c('f-table',{attrs:{"fields":_vm.tableFields,"fixed":"","items":_vm.model.filters,"tbody-tr-class":"declarative-filter__row"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-form-select',{attrs:{"size":"sm","options":_vm.fieldNames},on:{"input":function($event){return item.resetOperation()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Select field")])]},proxy:true}],null,true),model:{value:(item.field),callback:function ($$v) {_vm.$set(item, "field", $$v)},expression:"item.field"}})]}},{key:"cell(option)",fn:function(ref){
var item = ref.item;
return [_c('b-form-select',{attrs:{"disabled":!item.field,"size":"sm","options":item.availableOperations,"text-field":"label","value-field":"value"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select option")])]},proxy:true}],null,true),model:{value:(item.option),callback:function ($$v) {_vm.$set(item, "option", $$v)},expression:"item.option"}})]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
return [(item.option)?_c('div',[(item.valueType === 'input')?[_c('b-form-input',{attrs:{"size":"sm","type":item.inputType},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:_vm._e(),(item.valueType === 'date_or_time')?[_c('b-form-input',{attrs:{"size":"sm","type":item.inputType,"value":item.value},on:{"input":function($event){return item.setDateTime($event)}}})]:(item.valueType === 'radio-group')?[_c('b-form-radio-group',{attrs:{"size":"sm","options":[
              { value: 'true', text: 'TRUE' },
              { value: 'false', text: 'FALSE' } ]},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:(item.valueType === 'datetime')?[_c('div',{staticClass:"declarative-filter__date-time"},[_c('div',[_c('b-form-input',{attrs:{"type":"date","size":"sm","value":item.dateValue},on:{"input":function($event){return item.setDateTime($event, 'date')}}})],1),_c('div',[_c('b-form-input',{attrs:{"type":"time","size":"sm","value":item.timeValue},on:{"input":function($event){return item.setDateTime($event, 'time')}}})],1)])]:(item.valueType === 'multipicklist')?[_c('b-form-input',{attrs:{"placeholder":"Example: One;Two;Three","size":"sm"}})]:_vm._e()],2):_c('b-form-input',{attrs:{"disabled":"","size":"sm"}})]}},{key:"cell(remove)",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"declarative-filter__remove app-text--16 d-flex",on:{"click":function($event){return _vm.remove(item)}}},[_c('f-icon',{attrs:{"name":"trash"}})],1)]}},{key:"cell(condition)",fn:function(ref){
            var index = ref.index;
return [(index !== _vm.model.filters.length - 1 && !_vm.showCustomFilter)?_c('span',{staticClass:"app-text--14 font-weight-normal p-2"},[_vm._v(" AND ")]):_vm._e()]}},{key:"cell(index)",fn:function(ref){
            var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}}],null,false,1166842609)}):_vm._e(),_c('div',{staticClass:"d-flex px-3"},[(_vm.model.filters.length > 1)?_c('div',{staticClass:"text-left"},[_c('b-checkbox',{attrs:{"size":"sm"},model:{value:(_vm.showCustomFilter),callback:function ($$v) {_vm.showCustomFilter=$$v},expression:"showCustomFilter"}},[_vm._v(" Custom filter ")]),(_vm.showCustomFilter)?_c('b-form-input',{staticClass:"mt-1",attrs:{"size":"sm","placeholder":"(1 AND 2) OR 3"},model:{value:(_vm.customFilter),callback:function ($$v) {_vm.customFilter=$$v},expression:"customFilter"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"ml-auto"},[(_vm.model.filters.length !== _vm.maxItems)?_c('div',{on:{"click":_vm.add}},[_c('f-icon',{staticClass:"declarative-filter__add",attrs:{"name":"add_circled"}})],1):_c('span',{staticClass:"declarative-filter__reject"},[_vm._v("You can add only "+_vm._s(_vm.maxItems)+" filters")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }