import { __decorate } from "tslib";
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { RestoreMode, } from '@flosum-backup/contracts';
import RestoreApi from '@/services/api/restore.api';
import DeclarativeFilter from '@/components/shared/DeclarativeFilter.vue';
let RestoreSettings = class RestoreSettings extends Vue {
    constructor() {
        super(...arguments);
        this.restoreModes = [
            { name: RestoreMode.Basic, label: 'Basic', icon: 'upload' },
            { name: RestoreMode.Selective, label: 'Selective', icon: 'multiselect' },
            { name: RestoreMode.Removed, label: 'Removed', icon: 'list' },
        ];
        this.selectedRestoreMode = RestoreMode.Basic;
        this.selectedObjects = [];
        this.loading = false;
        this.objectSearchText = '';
        this.objects = [];
        this.describe = {};
        this.filtersObject = null;
    }
    get visibleObjects() {
        const regexp = new RegExp(this.objectSearchText, 'i');
        return this.objects.filter(object => regexp.test(object.name));
    }
    get objectFields() {
        const objectName = this.filtersObject?.name;
        if (!objectName) {
            return [];
        }
        const backupObject = this.objects.find(object => object.name === objectName);
        if (!backupObject) {
            return [];
        }
        return backupObject.fields;
    }
    get disabledFields() {
        const objectName = this.filtersObject?.name;
        if (!objectName) {
            return [];
        }
        const backupObject = this.objects.find(object => object.name === objectName);
        if (!backupObject) {
            return [];
        }
        return this.getDisabledFieldsFor(objectName, backupObject.fields);
    }
    get fieldsWithTypes() {
        const fields = this.describe[this.filtersObject?.name || ''].fields || {};
        const fieldsList = this.objectFields;
        return fieldsList
            .filter(field => fields[field]?.isFilterable)
            .reduce((acc, field) => ({ ...acc, [field]: fields[field].type }), {});
    }
    mounted() {
        this.fetchBackupDescribe();
    }
    selectMode(mode) {
        this.selectedRestoreMode = mode;
        this.filtersObject = null;
        this.objects = this.objects.map(object => {
            if (object.name.endsWith('__b')) {
                object.disabled = this.selectedRestoreMode === RestoreMode.Removed;
                if (object.selected && this.selectedRestoreMode === RestoreMode.Removed) {
                    object.selected = false;
                }
            }
            return object;
        });
        if (this.selectedRestoreMode === RestoreMode.Removed) {
            this.selectedObjects = this.selectedObjects.filter(object => !object.name.endsWith('__b'));
        }
    }
    toggleObject(selectedObject) {
        this.objects = this.objects.map(object => {
            if (selectedObject === object) {
                object.selected = !object.selected;
            }
            return object;
        });
        const { id, name } = selectedObject;
        if (this.selectedObjects.find(object => object.backupOptionId === id)) {
            this.selectedObjects = this.selectedObjects.filter(object => object.backupOptionId !== id);
            if (this.filtersObject?.backupOptionId === id) {
                this.filtersObject = null;
            }
        }
        else {
            this.selectedObjects.push({
                name,
                backupOptionId: id,
                declarative: {
                    filters: [],
                },
                fields: this.getRequiredFieldsFor(name),
            });
        }
    }
    showFilters(objectToShown) {
        const selectedObject = this.selectedObjects.find(object => object.backupOptionId === objectToShown.id);
        if (selectedObject) {
            this.filtersObject = selectedObject;
        }
    }
    onRestoreSettingsModelChanged() {
        this.$emit('changed', {
            mode: this.selectedRestoreMode,
            executionStepsDetails: this.selectedObjects.map(({ name, fields, declarative, backupOptionId }) => ({
                name,
                backupOptionId,
                fields,
                declarativeFilter: declarative.filters.map(({ field, option, value }) => ({
                    field,
                    option: option,
                    value,
                })),
                declarativeFilterCustomLogic: declarative.customLogic || null,
            })),
        });
    }
    getDisabledFieldsFor(object, backupFields) {
        const fields = this.describe[object].fields || {};
        const fieldsList = Object.keys(fields);
        return backupFields.filter(field => fields[field]?.isRequired || !fieldsList.includes(field) || !fields[field]?.isCreateable);
    }
    getRequiredFieldsFor(object) {
        const fields = this.describe[object].fields || {};
        const fieldsList = Object.keys(fields);
        return fieldsList.filter(field => fields[field].isRequired && (fields[field].isCreateable || field === 'Id'));
    }
    async fetchBackupDescribe() {
        try {
            this.loading = true;
            const backupObjects = await RestoreApi.describeBackup({ backupId: this.backupId });
            this.describe = await RestoreApi.describeObjects({
                objectNames: backupObjects.map(describe => describe.name),
                organizationId: this.organizationId,
                projectName: this.projectName,
            });
            this.objects = backupObjects.map(object => ({
                ...object,
                selected: false,
                disabled: !this.describe[object.name] || !this.describe[object.name].isCreateable,
            }));
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop()
], RestoreSettings.prototype, "organizationId", void 0);
__decorate([
    Prop()
], RestoreSettings.prototype, "projectName", void 0);
__decorate([
    Prop()
], RestoreSettings.prototype, "backupId", void 0);
__decorate([
    Model('changed')
], RestoreSettings.prototype, "model", void 0);
__decorate([
    Watch('selectedRestoreMode', { immediate: true }),
    Watch('selectedObjects', { deep: true })
], RestoreSettings.prototype, "onRestoreSettingsModelChanged", null);
RestoreSettings = __decorate([
    Component({
        components: {
            DeclarativeFilter,
        },
    })
], RestoreSettings);
export default RestoreSettings;
