import moment from 'moment';
import { DeclarativeFilterOptionsEnum as Option } from '@flosum-backup/contracts';
const STRING_TYPES = ['id', 'reference', 'url', 'email', 'phone', 'picklist', 'textarea', 'string'];
const NUMBER_TYPES = ['integer', 'double', 'int', 'currency', 'percent'];
const DATE_TIME_TYPES = ['datetime', 'time', 'date'];
const BOOLEAN_TYPES = ['boolean'];
const MULTI_PICK_LIST_TYPES = ['multipicklist'];
function createOperation(operation, types) {
    return {
        value: operation,
        label: operation,
        types,
    };
}
export const OPERATIONS_FIELDS = [
    createOperation(Option.Equals, [
        ...STRING_TYPES,
        ...NUMBER_TYPES,
        ...BOOLEAN_TYPES,
        ...DATE_TIME_TYPES,
        ...MULTI_PICK_LIST_TYPES,
    ]),
    createOperation(Option.NotEquals, [
        ...STRING_TYPES,
        ...NUMBER_TYPES,
        ...BOOLEAN_TYPES,
        ...DATE_TIME_TYPES,
        ...MULTI_PICK_LIST_TYPES,
    ]),
    createOperation(Option.Contains, [...STRING_TYPES]),
    createOperation(Option.DoesNotContain, [...STRING_TYPES]),
    createOperation(Option.StartsWith, [...STRING_TYPES]),
    createOperation(Option.LessThan, [...NUMBER_TYPES, ...DATE_TIME_TYPES]),
    createOperation(Option.GreaterThan, [...NUMBER_TYPES, ...DATE_TIME_TYPES]),
    createOperation(Option.LessOrEquals, [...NUMBER_TYPES, ...DATE_TIME_TYPES]),
    createOperation(Option.GreaterOrEquals, [...NUMBER_TYPES, ...DATE_TIME_TYPES]),
    createOperation(Option.Includes, [...MULTI_PICK_LIST_TYPES]),
    createOperation(Option.Excludes, [...MULTI_PICK_LIST_TYPES]),
];
export class DeclarativeFilterItem {
    constructor(field = '', operation = null, value = '', fields = {}) {
        this.dateValue = '';
        this.timeValue = '';
        this.field = '';
        this.value = '';
        this.option = null;
        this.field = field;
        this.option = operation;
        this.value = value;
        this.fields = fields;
        this.setType();
        if (this.type === 'datetime') {
            this.dateValue = moment(value).format('YYYY-MM-DD');
            this.timeValue = moment(value).format('HH:mm');
        }
    }
    get isValid() {
        return !!this.field && !!this.option;
    }
    get availableOperations() {
        const operations = OPERATIONS_FIELDS.filter(({ types }) => types.includes(this.type));
        return operations.length ? operations : OPERATIONS_FIELDS;
    }
    get valueType() {
        if (BOOLEAN_TYPES.includes(this.type)) {
            return 'radio-group';
        }
        if (this.type === 'date' || this.type === 'time') {
            return 'date_or_time';
        }
        if (this.type === 'datetime' || this.type === 'multipicklist') {
            return this.type;
        }
        return 'input';
    }
    get inputType() {
        if (NUMBER_TYPES.includes(this.type)) {
            return 'number';
        }
        if (this.type === 'phone') {
            return 'tel';
        }
        if (['email', 'date', 'time'].includes(this.type)) {
            return this.type;
        }
        return 'text';
    }
    setDateTime(value, dateTimePart) {
        if (this.type === 'date') {
            this.value = moment(value).format('YYYY-MM-DD');
        }
        else if (this.type === 'time') {
            this.value = value;
        }
        else if (this.type === 'datetime') {
            if (dateTimePart === 'date') {
                this.dateValue = value;
            }
            else if (dateTimePart === 'time') {
                this.timeValue = value;
            }
            if (this.dateValue && this.timeValue) {
                this.value = moment(`${this.dateValue} ${this.timeValue}`).toISOString();
            }
        }
    }
    resetOperation() {
        this.option = null;
        this.value = '';
        this.dateValue = '';
        this.timeValue = '';
        this.setType();
    }
    toJSON() {
        return {
            value: this.value,
            field: this.field,
            option: this.option,
        };
    }
    setType() {
        this.type = this.fields[this.field];
    }
}
