import { __decorate } from "tslib";
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { RestoreMode } from '@flosum-backup/contracts';
import Stepper from '@/components/shared/Stepper.vue';
import BackupsList from '@/components/shared/BackupsList.vue';
import RestoreSettings from '@/components/restore/RestoreSettings.vue';
import AutomationProcesses from '@/components/shared/AutomationProcesses.vue';
import RestoreApi from '@/services/api/restore.api';
import SelectRecords from '@/components/restore/SelectRecords.vue';
import BackupUtils from '@/utils/backup.utils';
let RestoreNewPage = class RestoreNewPage extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.backupId = -1;
        this.storageId = -1;
        this.projectName = '';
        this.restoreSettings = {};
        this.automationProcessSettings = {};
        this.selectedRecords = {};
        this.stepsList = [
            { title: 'Select backup', name: 'backups', active: true, done: false, shown: true },
            { title: 'Restore settings', name: 'settings', active: false, done: false, shown: true },
            {
                title: 'Selective records',
                name: 'selective',
                active: false,
                done: false,
                shown: false,
            },
            { title: 'Disable automation process', name: 'automation', active: false, done: false, shown: true },
        ];
    }
    get steps() {
        return this.stepsList.filter(step => step.shown);
    }
    get activeStep() {
        return this.steps.find(step => step.active)?.name || '';
    }
    get objects() {
        return this.restoreSettings.executionStepsDetails.map(({ name }) => name);
    }
    mounted() {
        this.createProject();
    }
    onRestoreModeChanged() {
        this.stepsList = this.stepsList.map(step => {
            if (step.name === 'selective') {
                step.shown = this.restoreSettings?.mode === RestoreMode.Selective;
            }
            return step;
        });
    }
    async createProject() {
        this.loading = true;
        try {
            this.loading = true;
            const { projectName } = await RestoreApi.createProject();
            this.projectName = projectName;
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    async next() {
        if (this.activeStep === 'settings') {
            if (!this.restoreSettings.executionStepsDetails.length) {
                this.$toast.error('Select at least one object to restore.');
                return;
            }
            for (const executionStepsDetail of this.restoreSettings.executionStepsDetails) {
                if (executionStepsDetail.declarativeFilter && executionStepsDetail.declarativeFilter.length) {
                    if (executionStepsDetail.declarativeFilter.some(filter => !filter.field || !filter.option)) {
                        this.$toast.error('Make sure that all rows in declarative filter has selected field and option!');
                        return;
                    }
                }
            }
        }
        if (this.activeStep === 'automation') {
            await this.restore();
            return;
        }
        this.nextStep();
    }
    async selectBackup(backup) {
        if (!backup) {
            return;
        }
        try {
            const storage = await BackupUtils.getStorageForBackup(backup);
            if (!storage) {
                return;
            }
            this.storageId = storage.id;
            this.backupId = backup.id;
            this.nextStep();
        }
        catch (error) {
            this.$toast.error(error);
        }
    }
    async restore() {
        try {
            this.loading = true;
            const { disableProcessBuilder, disableTrigger, disableValidationRule, disableWorkflow, } = this.automationProcessSettings;
            const { mode, executionStepsDetails } = this.restoreSettings;
            await RestoreApi.restore({
                organizationId: this.organizationId,
                projectName: this.projectName,
                storageId: this.storageId,
            }, {
                backupId: this.backupId,
                disableProcessBuilder,
                disableTrigger,
                disableValidationRule,
                disableWorkflow,
                mode,
                executionStepsDetails: executionStepsDetails.map(step => ({
                    ...step,
                    selectedIds: this.selectedRecords[step.name] || [],
                    disableAutomationProcess: this.automationProcessSettings.objects.includes(step.name),
                })),
            });
            this.$toast.success('Restore successfully queued.');
            await this.$router.replace({
                name: 'restore.index',
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    nextStep() {
        const activeStep = this.stepsList.find(step => step.active);
        const nextStep = this.stepsList.find((step, index) => step.shown && index > this.stepsList.indexOf(activeStep));
        if (activeStep) {
            activeStep.active = false;
            activeStep.done = true;
        }
        if (nextStep) {
            nextStep.active = true;
        }
    }
};
__decorate([
    Inject()
], RestoreNewPage.prototype, "organizationId", void 0);
__decorate([
    Watch('restoreSettings.mode')
], RestoreNewPage.prototype, "onRestoreModeChanged", null);
RestoreNewPage = __decorate([
    Component({
        components: {
            SelectRecords,
            AutomationProcesses,
            RestoreSettings,
            BackupsList,
            Stepper,
        },
    })
], RestoreNewPage);
export default RestoreNewPage;
